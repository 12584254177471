import React, { useEffect } from "react";

const CalendlyBadge: React.FC = () => {
  useEffect(() => {
    // Dynamically load Calendly CSS
    const link = document.createElement("link");
    link.href = "https://assets.calendly.com/assets/external/widget.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    // Dynamically load Calendly script
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    script.onload = () => {
      const calendly = (window as any).Calendly; // Use type assertion here
      if (calendly) {
        // Initialize the badge widget
        calendly.initBadgeWidget({
          url: "https://calendly.com/gilbertbatista-k/gilbert-batista-fullstack-devops-engineer",
          text: "Schedule time with me!",
          color: "#000000",
          textColor: "#ffffff",
        });
      } else {
        console.error("Calendly script failed to initialize.");
      }
    };
    document.body.appendChild(script);

    return () => {
      // Clean up dynamically added elements
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  return null; // No visible output
};

export default CalendlyBadge;
